@import './_variables.css';

h1, h2, h3, h4, h5, h6 {
    font-family: var(--font-family-sans-serif);
    color: black;

    margin-top: 0px;
    margin-bottom: 1rem;
}

h4 {
    font-weight: 600;
    font-size: 34px;
    line-height: 46px;
}

a:hover {
    color: var(--pink-lava) !important;
    text-decoration: underline;
}