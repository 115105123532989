@import './_variables.css';

/* MARGINS */
.m-0 {
    margin: 0px !important;
}
.mt-0 {
    margin-top: 0px !important;
}
.mr-0 {
    margin-right: 0px !important;
}
.mb-0 {
    margin-bottom: 0px !important;
}
.ml-0 {
    margin-left: 0px !important;
}

.m-25 {
    margin: var(--space-25) !important;
}
.mt-25 {
    margin-top: var(--space-25) !important;
}
.mr-25 {
    margin-right: var(--space-25) !important;
}
.mb-25 {
    margin-bottom: var(--space-25) !important;
}
.ml-25 {
    margin-left: var(--space-25) !important;
}

.m-50 {
    margin: var(--space-50) !important;
}
.mt-50 {
    margin-top: var(--space-50) !important;
}
.mr-50 {
    margin-right: var(--space-50) !important;
}
.mb-50 {
    margin-bottom: var(--space-50) !important;
}
.ml-50 {
    margin-left: var(--space-50) !important;
}

.m-75 {
    margin: var(--space-75) !important;
}
.mt-75 {
    margin-top: var(--space-75) !important;
}
.mr-75 {
    margin-right: var(--space-75) !important;
}
.mb-75 {
    margin-bottom: var(--space-75) !important;
}
.ml-75 {
    margin-left: var(--space-75) !important;
}

.m-1 {
    margin: var(--space-1) !important;
}
.mt-1 {
    margin-top: var(--space-1) !important;
}
.mr-1 {
    margin-right: var(--space-1) !important;
}
.mb-1 {
    margin-bottom: var(--space-1) !important;
}
.ml-1 {
    margin-left: var(--space-1) !important;
}

.m-125 {
    margin: var(--space-125) !important;
}
.mt-125 {
    margin-top: var(--space-125) !important;
}
.mr-125 {
    margin-right: var(--space-125) !important;
}
.mb-125 {
    margin-bottom: var(--space-125) !important;
}
.ml-125 {
    margin-left: var(--space-125) !important;
}

.m-150 {
    margin: var(--space-150) !important;
}
.mt-150 {
    margin-top: var(--space-150) !important;
}
.mr-150 {
    margin-right: var(--space-150) !important;
}
.mb-150 {
    margin-bottom: var(--space-150) !important;
}
.ml-150 {
    margin-left: var(--space-150) !important;
}

.m-2 {
    margin: var(--space-2) !important;
}
.mt-2 {
    margin-top: var(--space-2) !important;
}
.mr-2 {
    margin-right: var(--space-2) !important;
}
.mb-2 {
    margin-bottom: var(--space-2) !important;
}
.ml-2 {
    margin-left: var(--space-2) !important;
}

.m-3 {
    margin: var(--space-3) !important;
}
.mt-3 {
    margin-top: var(--space-3) !important;
}
.mr-3 {
    margin-right: var(--space-3) !important;
}
.mb-3 {
    margin-bottom: var(--space-3) !important;
}
.ml-3 {
    margin-left: var(--space-3) !important;
}

.m-4 {
    margin: var(--space-4) !important;
}
.mt-4 {
    margin-top: var(--space-4) !important;
}
.mr-4 {
    margin-right: var(--space-4) !important;
}
.mb-4 {
    margin-bottom: var(--space-4) !important;
}
.ml-4 {
    margin-left: var(--space-4) !important;
}

.m-auto {
    margin: auto !important;
}
.mt-auto {
    margin-top: auto !important;
}
.mr-auto {
    margin-right: auto !important;
}
.mb-auto {
    margin-bottom: auto !important;
}
.ml-auto {
    margin-left: auto !important;
}
/* MARGINS */

/* PADDINGS */
.p-0 {
    padding: 0px !important;
}
.pt-0 {
    padding-top: 0px !important;
}
.pr-0 {
    padding-right: 0px !important;
}
.pb-0 {
    padding-bottom: 0px !important;
}
.pl-0 {
    padding-left: 0px !important;
}

.p-25 {
    padding: var(--space-25) !important;
}
.pt-25 {
    padding-top: var(--space-25) !important;
}
.pr-25 {
    padding-right: var(--space-25) !important;
}
.pb-25 {
    padding-bottom: var(--space-25) !important;
}
.pl-25 {
    padding-left: var(--space-25) !important;
}

.p-75 {
    padding: var(--space-75) !important;
}
.pt-75 {
    padding-top: var(--space-75) !important;
}
.pr-75 {
    padding-right: var(--space-75) !important;
}
.pb-75 {
    padding-bottom: var(--space-75) !important;
}
.pl-75 {
    padding-left: var(--space-75) !important;
}

.p-1 {
    padding: var(--space-1) !important;
}
.pt-1 {
    padding-top: var(--space-1) !important;
}
.pr-1 {
    padding-right: var(--space-1) !important;
}
.pb-1 {
    padding-bottom: var(--space-1) !important;
}
.pl-1 {
    padding-left: var(--space-1) !important;
}

.p-125 {
    padding: var(--space-125) !important;
}
.pt-125 {
    padding-top: var(--space-125) !important;
}
.pr-125 {
    padding-right: var(--space-125) !important;
}
.pb-125 {
    padding-bottom: var(--space-125) !important;
}
.pl-125 {
    padding-left: var(--space-125) !important;
}

.p-150 {
    margin: var(--space-150) !important;
}
.pt-150 {
    padding-top: var(--space-150) !important;
}
.pr-150 {
    padding-right: var(--space-150) !important;
}
.pb-150 {
    padding-bottom: var(--space-150) !important;
}
.pl-150 {
    padding-left: var(--space-150) !important;
}

.p-2 {
    padding: var(--space-2) !important;
}
.pt-2 {
    padding-top: var(--space-2) !important;
}
.pr-2 {
    padding-right: var(--space-2) !important;
}
.pb-2 {
    padding-bottom: var(--space-2) !important;
}
.pl-2 {
    padding-left: var(--space-2) !important;
}

.p-3 {
    padding: var(--space-3) !important;
}
.pt-3 {
    padding-top: var(--space-3) !important;
}
.pr-3 {
    padding-right: var(--space-3) !important;
}
.pb-3 {
    padding-bottom: var(--space-3) !important;
}
.pl-3 {
    padding-left: var(--space-3) !important;
}

.p-4 {
    padding: var(--space-4) !important;
}
.pt-4 {
    padding-top: var(--space-4) !important;
}
.pr-4 {
    padding-right: var(--space-4) !important;
}
.pb-4 {
    padding-bottom: var(--space-4) !important;
}
.pl-4 {
    padding-left: var(--space-4) !important;
}
/* PADDINGS */

/* FLEX */
.d-flex {
    display: flex !important;
}
.flex-column {
    flex-direction: column !important;
}
.flex-column-reverse {
    flex-direction: column-reverse !important;
}
.flex-grow-0 {
    flex-grow: 0 !important;
}
.flex-grow-1 {
    flex-grow: 1 !important;
}

.align-items-center {
    align-items: center !important;
}

.align-items-end {
    align-items: end !important;
}

.justify-content-center {
    justify-content: center !important;
}

.justify-content-start {
    justify-content: start !important;
}

.justify-content-end {
    justify-content: end !important;
}
/* FLEX */

/* TEXT WEIGHTS */
.fw-bold {
    font-weight: bold !important;
}
.fw-bolder {
    font-weight: bolder !important;
}
/* TEXT WEIGHTS */

/* TEXT ALIGNMENTS */
.text-center {
    text-align: center !important;
}
/* TEXT ALIGNMENTS */

/* TEXT DECORATION */
.text-underline {
    text-decoration: underline !important;
}
/* TEXT DECORATION */

/* TEXT TRANSFORM */
.text-uppercase {
    text-transform: uppercase !important;
}
.text-lowercase {
    text-transform: lowercase !important;
}
.text-capitalize {
    text-transform: capitalize !important;
}
/* TEXT TRANSFORM */

/* TEXT COLORS */
.text-black {
    color: black !important;
}
.text-white {
    color: white !important;
}
.text-primary {
    color: var(--pink-lava);
}
.text-primary-dark {
    color: #BC006F !important;
}
.text-secondary {
    color: #666666 !important;
}
.text-info {
    color: #2BBECB !important;
}
.text-warning-darker {
    color: #FFB400 !important;
}
.text-danger {
    color: #ED1C24 !important;
}
.text-success-darker {
    color: #01A862 !important;
}

.text-wafer-blue-max {
    color: var(--wafer-blue-max) !important;
}
/* TEXT COLORS */
.bg-transparent {
    background-color: transparent !important;
}

.bg-white {
    background-color: white !important;
}

/* CURSORS */
.cursor-pointer {
    cursor: pointer !important;
}
/* CURSORS */