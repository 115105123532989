.login-page {
    min-height: 100vh;
    height: 100%;
    background: linear-gradient(180deg, #FFFFFF 18.39%, #D5FAFD 150.89%);
}

.login-wrapper {
    min-height: inherit;
    padding: 24px 24px 12px 24px;
    background-image: url('../images/backgrounds/login-bg.svg');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: 100%;
}

.login-logo {
    margin-top: 4px;
    margin-bottom: 42px;
}

.login-logo img {
    display: block;
    margin-inline: auto;
}

.login-form {
    padding: 32px !important;
    max-width: 473px;
    margin-inline: auto;
    box-shadow: 0px 4px 16px rgba(170, 170, 170, 0.15);
    border-radius: 24px !important;
}

.login-form .form-title {
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;

    text-align: center;
    color: #000000;
}

.login-form .form-subtitle {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;

    text-align: center;
    color: #888888;

    margin-top: 4px;
}

.login-footer {
    padding-top: 74px;
    margin-top: 65px;
    
    display: flex;
    justify-content: center;
    gap: 8px;

    font-weight: 500;
    font-size: 10px;
    line-height: 14px;

    color: #FFFFFF;
}