@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700&display=swap');

body {
  font-family: "Nunito Sans";
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#components-grid-demo-playground [class~='ant-col'] {
  background: transparent;
  border: 0;
}
#components-grid-demo-playground [class~='ant-col'] > div {
  height: 120px;
  font-size: 14px;
  line-height: 120px;
  background: #0092ff;
  border-radius: 4px;
}
#components-grid-demo-playground pre {
  padding: 8px 16px;
  font-size: 13px;
  background: #f9f9f9;
  border-radius: 6px;
}
#components-grid-demo-playground pre.demo-code {
  direction: ltr;
}
#components-grid-demo-playground .ant-col {
  padding: 0;
}

.site-calendar-demo-card {
  width: 300px;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
}

.ant-picker-panel .ant-picker-footer {
  display: none !important;
}

.ant-picker-date-panel {
  background: #FFFFFF;
  width: 362px;
  box-shadow: 0px 4px 16px rgba(170, 170, 170, 0.15);
  border-radius: 12px;
}

.ant-picker-date-panel .ant-picker-content {
  width: 339px;
}

.ant-picker-header {
  border-bottom : none;
}

.ant-picker-cell-inner {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}

.ant-picker-header-super-prev-btn {
  display: none;
}

.ant-picker-header-super-next-btn {
  display: none;
}

.ant-picker-header-next-btn {
  color: #2BBECB !important;
}

.ant-picker-header-prev-btn {
  color: #2BBECB !important;
}

.ant-picker-content th {
  color: #888888;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: #2BBECB;
  border-radius: 100px;
}

.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner, .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
  background: #D5FAFD;
  border-radius: 100px;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid  #2BBECB !important;
  border-radius: 100px;
}

.ant-picker-header-view button:hover {
  color: #2BBECB;
}

.ant-picker:hover, .ant-picker-focused {
  border-color: #2BBECB !important;
}

.ant-picker-focused {
  border-color: #2BBECB !important;
}

.ant-picker-year-panel {
  width: 362px;
}

.ant-picker-panel-container {
  width: 362px;
  background: #FFFFFF;
  box-shadow: 0px 4px 16px rgba(170, 170, 170, 0.15);
  border-radius: 12px;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: transparent !important;
  font-weight: normal;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: transparent !important;
}

.ant-select-item-option-active {
  background-color: '#AAE5EA' !important;
}


.ant-select-item {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

.ant-select-item-option-state {
  display: none;
}

.ant-select-selection-overflow {
  gap: 8px;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #2BBECB !important;
}

.ant-switch-checked {  
  background-color:#2BBECB !important;
}

.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 18px - 10px) !important;
}

.ant-tooltip-arrow-content {
  background-color: white;
}

.ant-tooltip-inner {
  color: #000000 !important;
  padding: 8px;
  box-shadow: 0px 4px 16px rgba(170, 170, 170, 0.15);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  background-color: white;
  border-radius: 4px;
  width: 128px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: #EB008B;
  font-weight: bold !important;
}

.ant-menu-item:hover {
  background: #FF34AC;
  color: white !important;
}

.ant-menu-light .ant-menu-submenu-active {
  background: #FF34AC !important;
  border-radius: 8px;
}

.ant-menu-light .ant-menu-submenu-title:hover {
  color: white !important;
}

.ant-menu-submenu-arrow {
  color: white;
}

.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  color: white;
}

.ant-menu-sub.ant-menu-inline {
  background-color:#2BBECB !important;
  padding-left: 24px;
}


.ant-input-textarea-show-count::after {
  position: absolute !important;
  right: 20px;
  top:  20px;
  display: none;
}

.ant-input:focus {
  border-color: #2BBECB !important;
}

.ant-picker-date-panel {
  background: #FFFFFF;
  box-shadow: 0px 4px 16px rgba(170, 170, 170, 0.15);
  border-radius: 0px 0px 12px 12px;
 }

 .site-calendar-demo-card {
  border-radius: 12px;
  background: #FFFFFF;
  box-shadow: 0px 4px 16px rgba(170, 170, 170, 0.15);
 }

 .ant-picker-calendar-mini {
  border-radius: 12px;
  background: #FFFFFF;
  box-shadow: 0px 4px 16px rgba(170, 170, 170, 0.15);
 }

 .ant-picker-calendar-mini .ant-picker-panel {
  background: #FFFFFF;
  box-shadow: 0px 4px 16px rgba(170, 170, 170, 0.15);
  border-radius: 0px 0px 12px 12px;
 }

 .ant-notification-notice {
   width: 328px;
   height: 36px;
   background: #165F66;
   border-radius: 8px;
   margin: 0;
   padding: 6px 10px;
 }

 .ant-notification-notice {
  height: 100%;
 }

 .ant-notification-notice-message {
   margin:0px;
   font-weight: 500;
   font-size: 14px;
   line-height: 24px;
   color: #FFFFFF;
 }

 .ant-notification-notice-close {
   top: 8px;
   color: #1E858E;
 }

 .ant-notification-notice-with-icon .ant-notification-notice-message {
  margin-bottom: 0px;
  margin-left: 29px;
 }

 .ant-notification-notice-close:hover {
  color: #1E858E;
 }

 .ant-layout-sider-children {
   display: flex;
   flex-direction: column;
 }

 .ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon + span {
   display: none;
 }

 .ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon + span {
   display: none;
 }

 .ant-menu-submenu-popup > .ant-menu {
  border-radius: 4px;
  padding: 6px 11px;
 }

 .ant-menu-vertical.ant-menu-sub .ant-menu-item {
   width: unset !important;
   color: black !important;
 }

 .ant-menu-vertical.ant-menu-sub .ant-menu-item-active {
  color: white !important;
}

.ant-menu-vertical.ant-menu-sub .ant-menu-item-selected {
  color: white !important;
}

.ant-menu.ant-menu-sub .ant-menu-item-selected {
  width: 195px !important;
}

.ant-menu.ant-menu-sub .ant-menu-item-active {
  width: 195px !important;
}

.ant-select-item-option-grouped {
  padding-left: 0px !important;
}

.ant-select-item-group .ant-select-item {
  padding-left: 0px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 18px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  min-height: 24px !important;
  display: flex !important;
  align-items: center !important;
}

.ant-select-item.ant-select-item-option.ant-select-item-option-grouped {
  padding-left: 0px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 18px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  min-height: 24px !important;
  display: flex !important;
  align-items: center !important;
}

.ant-select-item.ant-select-item-group {
  padding-left: 0px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 18px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  min-height: 24px !important;
  display: flex !important;
  align-items: center !important;
}

.ant-modal-title {
  font-style: normal;
  font-weight: 600;
  font-size: 34px;
  line-height: 46px;
  color: #000000;
}

.ant-modal-content {
  border-radius: 16px;
}

.ant-modal-header {
  border-bottom: none;
  border-radius: 16px 16px 0 0;
}

.ant-modal-footer {
  border-top: none;
}

.ant-btn-default {
  display: none;
}

.ant-btn-primary {
  padding: 12px 24px;
  gap: 8px;
  background: #EB008B;
  border-radius: 96px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 49px;
  margin-left: 0px;
}

.ant-btn-primary:hover, .ant-btn-primary:focus {
  border-color: #BC006F;
  background: #BC006F;
}

.reactEasyCrop_CropArea {
  width: 300px !important;
  height: 300px !important;
}

.img-crop-modal .img-crop-container {
  height: 300px !important
}

.reactEasyCrop_Contain {
  width: 300px !important;
  max-height: fit-content !important;
}