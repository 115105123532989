aside.ant-layout-sider {
    position: absolute;
    height: 100% !important;
    overflow-y: auto;
    overflow-x: hidden;
}

.ant-notification .toast-danger {
    background: #ED1C24;
}

.ant-notification .toast-danger .ant-notification-notice-message,
.ant-notification .toast-danger .ant-notification-notice-description,
.ant-notification .toast-danger .ant-notification-notice-close .ant-notification-notice-close-icon {
    color: #FFFFFF;
}

.ant-menu-inline.ant-menu-root .ant-menu-item.ant-menu-item-only-child {
    width: 195px !important;
    height: auto;
}

.ant-menu-inline.ant-menu-root .ant-menu-item>.ant-menu-title-content,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title>.ant-menu-title-content {
    overflow-wrap: break-word;
    white-space: normal;
}

.ant-radio-group .ant-radio-wrapper {
    display: flex;
    align-items: center;
}

.ant-radio-group .ant-radio+span {
    padding-top: 6px;
}

.ant-table-ping-left:not(.ant-table-has-fix-left)>.ant-table-container::before,
.ant-table-ping-right:not(.ant-table-has-fix-right)>.ant-table-container::after {
    box-shadow: none;
}

.ant-table .ant-table-cell-no-shadow::after {
    box-shadow: none !important;
}

.ant-table .ant-table-cell .ant-checkbox-hidden {
    display: none;
}

aside.ant-layout-sider::-webkit-scrollbar-track:hover {
    -webkit-box-shadow: inset 1px 1px 0px #1A727A30;
    background-color: var(--wafer-blue-max);
}

aside.ant-layout-sider::-webkit-scrollbar {
    position: absolute;
    width: 8px;
    background-color: var(--wafer-blue-max);
    right: 10px;
}

aside.ant-layout-sider::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: var(--wafer-blue-max);
}

aside.ant-layout-sider::-webkit-scrollbar-thumb:hover {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px #2BBECB30;
    background-color: #1E858E;
}

.ant-table-wrapper.bg-header thead.ant-table-thead>tr {
    background-color: #f4f4f4;
}

.ant-table-wrapper.short-header thead.ant-table-thead>tr th {
    height: 27px !important;
    padding-top: 2px;
    padding-bottom: 0px;
}

.ant-table-thead>tr:first-of-type .ant-table-cell.cell-bordered {
    border-bottom: 1px solid #AAAAAA50;
}

.ant-table-cell.cell-bordered {
    border-left: 1px solid #AAAAAA50;
    border-right: 1px solid #AAAAAA50;
}

th.ant-table-cell.th-text-small {
    font-weight: 400 !important;
    font-size: 12px !important;
    color: #666666;
}
.ant-select.custom-dropdown > .ant-select-arrow {
    display: none;
}

.ant-select.custom-dropdown .ant-select-selector::after {
    background-image: var(--chevron-down);
    width: 24px;
    visibility: visible;
    line-height: unset;
    
    position: absolute;
    right: 14px;
}

.ant-select.custom-dropdown .ant-select-selection-overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-wrap: nowrap;
    gap: 4px;
}

.ant-select.custom-dropdown .ant-select-selection-item,
.ant-select.custom-dropdown .ant-select-selection-overflow-item {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    color: #000000;
}

.ant-select-dropdown.custom-dropdown-popup .rc-virtual-list-holder {
    overflow-x: hidden !important;
}

.ant-select-dropdown.custom-dropdown-popup:not(.custom-dropdown-popup-grouped) .rc-virtual-list-holder-inner {
    gap: 12px;
}

.ant-select-dropdown.custom-dropdown-popup .rc-virtual-list-holder-inner .ant-select-item {
    padding: 0px 12px;
    min-height: 0px;
}

.ant-select-dropdown.custom-dropdown-popup button.dd-clear {
    border-top: 1px solid #F4F4F4;
    text-align: center;
    font-weight: 600;
    color: var(--pink-lava);
    padding-block: 8px;
    width: 100%;
    border-radius: 0px;
}

.ant-select-dropdown.custom-dropdown-popup.custom-dropdown-popup-grouped .ant-select-item {
    padding-left: 12px !important;
}

.ant-select-dropdown.custom-dropdown-popup.custom-dropdown-popup-grouped .ant-select-item-option {
    font-size: 14px !important;
    margin-bottom: 4px;
}

.ant-select-dropdown.custom-dropdown-popup .rc-virtual-list-holder::-webkit-scrollbar {
    width: 8px;
}

.ant-select-dropdown.custom-dropdown-popup .rc-virtual-list-holder::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.ant-select-dropdown.custom-dropdown-popup .rc-virtual-list-holder::-webkit-scrollbar-thumb {
    background: #AAAAAA;
    border-radius: 64px;
}

.ant-select-dropdown.custom-dropdown-popup .rc-virtual-list-holder::-webkit-scrollbar-thumb:hover {
    background: #888888;
}

.ant-picker-decade-panel {
    width: 362px;
}

.custom-range-picker-popup .ant-picker-header-next-btn {
    visibility: visible !important;
}

.custom-range-picker-popup .ant-picker-header-super-prev-btn,
.custom-range-picker-popup .ant-picker-header-super-next-btn,
.custom-range-picker-popup .ant-picker-header-prev-btn,
.custom-range-picker-popup .ant-picker-header-next-btn {
    color: #2BBECB !important;
}

.custom-range-picker-popup .ant-picker-month-panel .ant-picker-header-super-prev-btn,
.custom-range-picker-popup .ant-picker-month-panel .ant-picker-header-super-next-btn {
    display: block !important;
}

.custom-range-picker-popup .ant-picker-month-panel .ant-picker-header-super-next-btn .ant-picker-super-next-icon {
    visibility: visible !important;
}
