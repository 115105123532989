@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;800&display=swap');

/* @font-face {
    font-family: '';
    src: url();
} */

html {
    --font-family-sans-serif: 'Nunito Sans';

    /* COLORS */
    --pink-lava: #EB008B;

    --wafer-blue-max: #2BBECB;
    /* COLORS */

    /* SPACINGS */
    --space-25: .25rem;
    --space-50: .5rem;
    --space-75: .75rem;
    --space-1: 1rem;
    --space-125: 1.25rem;
    --space-150: 1.5rem;
    --space-2: 2rem;
    --space-3: 3rem;
    --space-4: 4rem;
    /* SPACINGS */

    /* ICONS */
    --chevron-down: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.0611 7.85374C18.9218 7.71435 18.7564 7.60378 18.5744 7.52834C18.3923 7.45289 18.1972 7.41406 18.0001 7.41406C17.8031 7.41406 17.6079 7.45289 17.4259 7.52834C17.2438 7.60378 17.0784 7.71435 16.9391 7.85374L12.3531 12.4387C12.2594 12.5325 12.1322 12.5851 11.9996 12.5851C11.867 12.5851 11.7399 12.5325 11.6461 12.4387L7.06113 7.85374C6.77986 7.57235 6.39834 7.41421 6.00048 7.41411C5.60262 7.41402 5.22102 7.57198 4.93963 7.85324C4.65823 8.1345 4.50009 8.51603 4.5 8.91389C4.49991 9.31175 4.65786 9.69335 4.93913 9.97474L9.52513 14.5607C9.85014 14.8858 10.236 15.1436 10.6607 15.3195C11.0853 15.4955 11.5405 15.586 12.0001 15.586C12.4598 15.586 12.9149 15.4955 13.3396 15.3195C13.7643 15.1436 14.1501 14.8858 14.4751 14.5607L19.0611 9.97474C19.3423 9.69345 19.5003 9.31199 19.5003 8.91424C19.5003 8.5165 19.3423 8.13503 19.0611 7.85374Z' fill='%23444444'/%3E%3C/svg%3E%0A");
    /* ICONS */
}