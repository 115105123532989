.dashboard-greeting-card {
    padding: 14px 20px !important;
    background-image: url('../images/backgrounds/greeting-pattern.svg') !important;
    background-position: bottom !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
}

.dashboard-content-card {
    padding: 0px !important;
    min-height: 610px !important;
    background: linear-gradient(338.23deg, #2BBECB 1.93%, #F4FBFC 97.4%) !important;

    display: flex !important;
    flex-direction: column !important;
    border-radius: 16px 16px 20px 20px !important;
}

.dashboard-content-card .content-title {
    margin: 30px 24px !important;
}

.dashboard-content-card .inner-card {
    flex-grow: 1 !important;
}

.dashboard-content-card .inner-card .content-preview {
    height: auto;
    width: 100%;
    border-radius: 16px;
    margin-top: 8px;
}

.dashboard-content-card .ant-collapse-borderless {
    background-color: #FFFFFF;
    border-radius: 16px;

    font-family: 'Nunito Sans';
}
.dashboard-content-card .ant-collapse:not(:first-of-type) {
    margin-top: 10px;
}

.dashboard-content-card .ant-collapse .ant-collapse-header {
    flex-direction: row-reverse;
    padding: 16px 16px 8px 16px;
    
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;

    display: flex;
    align-items: center;

    color: #000000;
}

.dashboard-content-card .ant-collapse .ant-collapse-content {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.dashboard-content-card .ant-collapse .ant-collapse-header .ant-collapse-expand-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
    background-color: #F4F4F4;
    border-radius: 4px;

    transform: translateY(-3px);
}

.dashboard-content-card .ant-collapse .ant-collapse-header .ant-collapse-expand-icon .anticon {
    margin: 0px;
}

.dashboard-content-card .ant-collapse .ant-collapse-header .ant-collapse-expand-icon .anticon svg {
    transform: rotate(-90deg);
}