@import "./_variables.css";
@import "./_typography.css";
@import "./_utility.css";
@import "./antd-extended.css";

@import "./page-dashboard.css";
@import "./page-login.css";

html,
html body,
#root,
.content {
  height: fit-content;
}

html {
  min-height: 100%;
}

html body,
#root {
  min-height: 100vh;
}

.content {
  position: relative;
}

body {
  margin: 0;
  font-size: 16px;
  font-family: var(--font-family-sans-serif);
  background-color: #f4f4f4;
}

.content-wrapper {
  height: fit-content;
  min-height: 100vh;
  padding-top: 78px;
  padding-bottom: 1.25rem;
  display: flex;
  flex-direction: column;
  transition: all 0.2s;
}

.content-body {
  margin: 11px 20px 0px;
  min-height: 100%;

  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.line-through {
  text-decoration-line: line-through;
}

tr.coret td {
  text-decoration-line: line-through;
}

.capitalize {
  text-transform: lowercase !important;
}

.capitalize:first-line{
  text-transform: capitalize !important;
}